<template>
<div id="bindDevice">
    <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="用户编号">
            <el-input v-model="form.usernumber" placeholder="请输入用户编号"></el-input>
        </el-form-item>
        <el-form-item label="用户姓名">
            <el-input v-model="form.username" placeholder="请输入用户姓名"></el-input>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" :loading="loadingstate" @click="onSubmit">设置通知提醒</el-button>
        </el-form-item>
    </el-form>
</div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                usernumber: '',
                username: ''
            },
            loadingstate: false,
            jsCode: '',
            appid: ''
        }
    },
    mounted() {
        if (this.$route.query.code) {
            this.jsCode = this.$route.query.code
            if (this.$route.query.appid) {
                this.appid = this.$route.query.appid
            } else {
                this.$nextTick(() => {
                    this.login()
                })
            }
        }
    },
    methods: {
        onSubmit() {
            if (this.form.usernumber.toString().trim() == "") {
                this.$message.warning('用户编号不能为空');
                return
            }
            if (this.form.username.toString().trim() == "") {
                this.$message.warning('用户姓名不能为空');
                return
            }
            let params = {
                jsCode: this.jsCode,
                appId: this.appid,
                userNumber: this.form.usernumber,
                userName: this.form.username
            }
            this.loadingstate = true
            this.$api.setNotice(params).then(res => {
                console.log(params)
                this.loadingstate = false
                console.log("sign返回的信息", res)
                if (res.code == 1) {
                    this.$message.success('已设置完成');
                    setTimeout(() => {
                        location.reload()
                    }, 2000);
                } else {
                    this.$message.error(`${res.msg}`);
                    setTimeout(() => {
                        location.reload()
                    }, 2000);
                }
            })
        }
    }
}
</script>

<style lang="scss">
#bindDevice {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 100px 20px 20px 20px;
}
</style>
